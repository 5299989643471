import React from "react";
import { Link } from "react-router-dom";

const AllPrograms = ({
  showWeightloss,
  showPcos,
  showDiabetics,
  className,
}) => {
  const otherPages = [
    {
      link: "/weight-loss",
      title: "Weight loss Program",
      showOption: showWeightloss,
    },
    {
      link: "/pcos-reversal",
      title: "PCOS Reversal Program",
      showOption: showPcos,
    },
    {
      link: "/diabetes-reversal",
      title: "Diabetic Reversal Program",
      showOption: showDiabetics,
    },
  ];

  return (
    <div className={className}>
      <div className="d-flex justify-content-center  pt-5">
        <h4 className="programs-heading mb-0 text-center w-75">
          Other Programs
        </h4>
      </div>
      <div className="d-flex align-items-center justify-content-center gap-3 my-4 flex-wrap pb-5 pt-2">
        {otherPages.map((program, index) => (
          <>
            {program.showOption && (
              <Link
                to={program.link}
                key={index}
                className="program-buttons redirect-buttons"
              >
                {program.title}
                <img
                  src="/images/wl/arrow_forward.svg"
                  className="link-arrow-forward"
                />
                <img
                  src="/images/wl/arrow_forward_gray.svg"
                  className="link-arrow-forward-gray"
                />
              </Link>
            )}
          </>
        ))}
      </div>
    </div>
  );
};

export default AllPrograms;
