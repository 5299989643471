// GoogleSiteVerification.js

import React from "react";
import { Helmet } from "react-helmet";
// import SchemaScript from "./scripts/SchemaScript";
// import ReviewLink from "./scripts/review";
// import SitemapGenerator from "./scripts/SitemapGenerator";

const GoogleSiteVerification = () => (
  <Helmet>
    <meta
      name="google-site-verification"
      content={"EL-RGxX4fHGuQtSe6weHY-aSjQ1SfvVkfCKBgWPTMg8"}
    />
     {/* <SchemaScript /> */}
     {/* <ReviewLink /> */}
     {/* <SitemapGenerator /> */}

  </Helmet>
);

export default GoogleSiteVerification;
