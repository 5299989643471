import React, { useEffect, useState } from "react";
import GoogleSiteVerification from "../../google-site-verification";
import { Link, useNavigate } from "react-router-dom";
import Facebooksvg from "../../assets/images/footerIcons/facebook.svg";
import Instagramsvg from "../../assets/images/footerIcons/instagram.svg";
import Youtubesvg from "../../assets/images/footerIcons/youtube.svg";
import Pinterest from "../../assets/images/footerIcons/pinterest.png";
import LinkedinSVG from "../../assets/images/footerIcons/linkedin.svg";
import Thread from "../../assets/images/footerIcons/thread.svg";
import Twittersvg from "../../assets/images/footerIcons/twitter.svg";
import Ticon from "../../assets/images/footerIcons/t-icon.png";
import TalkToExperts from "../../components/talk-to-experts/TalkToExperts";
import AllPrograms from "@components/all-programs/AllPrograms";

function WeightLossPage(props) {
  const data = [
    "https://admin.healthtunnel.com/uploads/Group_9320_6285c898b0.png",
    "https://admin.healthtunnel.com/uploads/weightloss_121_6843bc5574.png",
    "https://admin.healthtunnel.com/uploads/yoga_lessongs_1_1225585358.png",
    "https://admin.healthtunnel.com/uploads/fitness_trainer_5076ed3d90.png",
  ];

  /* -------------------------------------------------------------------------- */
  /*                              UseEffect Section                             */
  /* -------------------------------------------------------------------------- */
  // useEffect(() => {
  //   getTestimonials();
  // }, []);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const [isLoading, setIsLoading] = useState(false);
  // const [testimonials, setTestimonials] = useState([]);
  // const getTestimonials = async () => {
  //   try {
  //     setIsLoading(true);
  //     let payload = {
  //       populate: "image",
  //     };
  //     let queryRequest = QueryRequestHelper(payload);
  //     let resp = await getAllTestimonials(queryRequest);
  //     if (resp) {
  //       setTestimonials(
  //         getValue(resp, `data`, []).map((item) => ({
  //           ...item,
  //           ...getValue(item, `attributes`, {}),
  //         }))
  //       );
  //       setIsLoading(false);
  //     } else {
  //       setIsLoading(false);
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //   }
  // };
  /* -------------------------------------------------------------------------- */
  /*                                Helper section                              */
  /* -------------------------------------------------------------------------- */
  const navigate = useNavigate();

  //Carousal
  const [currentIndex, setCurrentIndex] = useState(0);
  const slides = [
    "/images/wl/carousal-1.webp",
    "/images/wl/carousal-2.webp",
    "/images/wl/carousal-3.webp",
  ];

  const customerDp = [
    {
      user: "/images/wl/customer-dp-1.png",
      left: "0",
      zIndex: "4",
    },
    {
      user: "/images/wl/customer-dp-2.png",
      left: "36",
      zIndex: "3",
    },
    {
      user: "/images/wl/customer-dp-3.png",
      left: "72",
      zIndex: "2",
    },
    {
      user: "/images/wl/customer-dp-4.png",
      left: "108",
      zIndex: "1",
    },
  ];

  const programsButton = [
    "Nutritionist",
    "Fitness Coaches",
    "Dietitians",
    "Lifestyle Coaches",
  ];

  const otherPages = [
    {
      link: "",
      title: "PCOS Reversal Program",
    },
    {
      link: "",
      title: "Diabetic Reversal Program",
    },
    {
      link: "",
      title: "Yoga Sessions",
    },
  ];

  const programList = [
    {
      title: "Weight Loss Program",
      description: [
        "Does losing weight feel like a never-ending tunnel? Health Tunnel can be your light at the end!",
        " Our tailored weight loss program helps you ditch the fad diets and find a sustainable approach to reach your goals. ",
        "Join Health Tunnel today and start your journey to a healthier you",
      ],
      image: "/images/wl/weightloss-1.webp",
      background: "#FFF3E0",
    },
    // {
    //   title: "PCOS Reversal Program",
    //   description: [
    //     "PCOS symptoms dragging you down? Don’t fight it alone. Health Tunnel’s targeted program is your partner in health.  ",
    //     "Get expert guidance and personalized strategies to manage PCOS and achieve hormonal balance.",
    //     "Join Health Tunnel today and reclaim control!",
    //   ],
    //   image: "/images/wl/pcos.webp",
    //   background: "#FEEAEF",
    // },
    // {
    //   title: "Diabetic Reversal Program",
    //   description: [
    //     "Battling diabetes limitations? It’s time to fight back.Health Tunnel’s innovative reversal program empowers you to take control. Get personalized guidance and proven strategies to manage.  ",
    //     "Unlock a life free from restrictions and reclaim your well-being.",
    //     "Join Health Tunnel today!",
    //   ],
    //   image: "/images/wl/sugar_test.webp",
    //   background: "#CBE8F3",
    // },
    // {
    //   title: "Yoga Sessions",
    //   description: [
    //     "Seeking a deeper connection to your well-being?Unwind and find inner peace at Health Tunnel’s nurturing yoga classes. Experience the transformative power of yoga. ",
    //     "Expert instructors tailor your practice, fostering harmony for body, mind, and spirit.",
    //     "Embark on a journey of self-discovery. Start today!",
    //   ],
    //   image: "/images/wl/yoga_lessons.webp",
    //   background: "#D7E0D3",
    // },
  ];

  const appFeatures = [
    {
      image: "/images/wl/handshake.svg",
      feature: "Interact Directly with Your Dietician/Nutritionist via Our App",
    },
    {
      image: "/images/wl/tag.svg",
      feature: "Customized Weight Management Programs Tailored Just for You",
    },
    {
      image: "/images/wl/people.svg",
      feature: "Expert Coaching Every Step of the Way",
    },
    {
      image: "/images/wl/growth.svg",
      feature:
        "Monitor Your Progress: Track Water Intake, Sleep Hours, Steps Count, and More",
    },
    {
      image: "/images/wl/shield.svg",
      feature: "Access a Library of Health Videos and Delicious Recipes",
    },
    {
      image: "/images/wl/devices.svg",
      feature: "Seamlessly Connect with Mi Band, Fitbit, and Google Fit",
    },
  ];

  const appJourney = [
    {
      image: "/images/wl/journey-1.svg",
      heading: "Download App",
    },
    {
      image: "/images/wl/journey-2.svg",
      heading: "Upload profile & Blood reports",
    },
    {
      image: "/images/wl/journey-3.svg",
      heading: "Select Programme",
    },
    {
      image: "/images/wl/journey-4.svg",
      heading: "Schedule Consultation",
    },
    {
      image: "/images/wl/journey-5.svg",
      heading: "Get customised Meal plan",
    },
    {
      image: "/images/wl/journey-6.svg",
      heading: "Track progress",
    },
  ];

  const testimonials = [
    {
      image: "/images/wl/testimonial-1.webp",
      name: "Apoorva",
      lostWeight: "19",
      months: "5",
      description:
        "Being a college student, I would spend 8 to 10 hours studying for my boards and gained a lot of weight due to which I lost my confidence, couldn’t fit into my favourite clothes My coaches at Healthtunnel encouraged me to shred those extra kilos and gain back my confidence,I could fit from XL to XS",
    },
    {
      image: "/images/wl/testimonial-2.webp",
      name: "Denna",
      lostWeight: "10",
      months: "3",
      description:
        "I had gained weight due to my stress levels. Hectic work schedule and uneven eating pattern, not sleeping getting enough. Messed up my hormone system, periods got irregular. Diagnosed with PCOS. But after 3 months I feel happy that I have lost 10 kilos Most importantly my periods have got regular",
    },
    {
      image: "/images/wl/testimonial-3.webp",
      name: "Khusboo",
      lostWeight: "15",
      months: "3",
      description:
        "I had to lose that belly fat Post pregnancy. I would feel very lethargic and fatigue doing the household chores and taking care of the baby since I was not able to lose excess weight and specially tummy fat I took a 3-month weight loss program from Healthtunnel. I feel energetic and very active at home.",
    },
    {
      image: "/images/wl/testimonial-4.webp",
      name: "Prakash",
      lostWeight: "30",
      weightBefore: "102",
      weightAfter: "72",
      months: "7",
      description:
        "Due to hectic schedule, I couldn’t eat meals at right time and would often eat out This increased my cholesterol levels, high BP. But Healthtunnel weight loss program did work as miracles to me. my cholesterol levels came under control and BP as well , I could see a difference in my skin tone",
    },
  ];
  return (
    <div className="home-pagev2-wrapper weight-loss-wrapper bg-white">
      <GoogleSiteVerification />

      <div className=" ">
        {/* <div
          className="cursor-pointer px-3 pt-3 back-button"
          onClick={() => navigate("/")}
        >
          <BackSvgComponent />
        </div> */}
        <div className="d-flex justify-content-center">
          <img src="/images/wl/banner-1.webp" className="banner-image" />
        </div>
      </div>
      <TalkToExperts selectedProgram="Weight Loss" />
      <div className="custom-container pt-5">
        <h6 className="programs-heading text-center">Tailor Made Programs</h6>

        <div className="d-flex align-items-center justify-content-center mt-4 flex-wrap gap-2">
          <div className=" position-relative review-customers">
            {customerDp.map((images, index) => (
              <img
                src={images.user}
                style={{
                  left: `${images.left}px`,
                  zIndex: `${images.zIndex}`,
                }}
                key={index}
                width={56}
                height={56}
                className="top-0 position-absolute"
              />
            ))}
          </div>
          <div className="ms-4 pe-2">
            <span className="customers-count">2,100</span>
            <p className="bold-weight">Happy Customers</p>
          </div>
          <div className="ms-4 customer-reviews ps-4">
            <span className="customers-count">4.8/5</span>
            <div className="d-flex align-items-center gap-3">
              <div className="d-flex align-items-center gap-1">
                <img src="/images/wl/star.svg" />
                <img src="/images/wl/star.svg" />
                <img src="/images/wl/star.svg" />
                <img src="/images/wl/star.svg" />
                <img src="/images/wl/star.svg" />
              </div>
              <p className="bold-weight">Ratings</p>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center gap-3 mt-5 flex-wrap">
          {programsButton.map((program, index) => (
            <div key={index} className="program-buttons">
              {program}
              <img src="/images/wl/awesome.svg" />
            </div>
          ))}
        </div>
        <div className=" container my-5 pb-5">
          {programList.map((program, index) => (
            <div
              key={index}
              className="program-details row flex-column-reverse flex-lg-row d-flex gap-3"
            >
              <div className="col-12 col-lg-5 me-lg-4 mt-4 mt-lg-0">
                <h4 className="program-title">{program.title}</h4>
                {program.description.map((desc, idx) => (
                  <p key={idx} className="mt-3 text-black text__20">
                    {desc}
                  </p>
                ))}
              </div>
              <div className="col-12 col-lg-6 p-0">
                <img src={program.image} className="program-image " />
              </div>
            </div>
          ))}
        </div>
      </div>
      <TalkToExperts selectedProgram="Weight Loss" />
      <div className="custom-container py-5  ">
        <div className="d-flex justify-content-center">
          <h4 className="programs-heading mb-0 text-center w-75">
            Success Stories - Why Customers Love Healthtunnel
          </h4>
        </div>

        <div className="d-flex flex-column gap-4 mt-5">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="testimonial-container">
              <div className="">
                <img src="/images/wl/quote-top.svg" className="quote-top" />
                <h4 className="program-title">
                  {testimonial.name} Lost {testimonial.lostWeight} kilos in{" "}
                  {testimonial.months} months{" "}
                  {testimonial.weightBefore && (
                    <>
                      {testimonial.weightBefore} kilos to{" "}
                      {testimonial.weightAfter} kilos
                    </>
                  )}
                </h4>
                <div className="d-flex align-items-center gap-1 mt-1">
                  <img src="/images/wl/star.svg" />
                  <img src="/images/wl/star.svg" />
                  <img src="/images/wl/star.svg" />
                  <img src="/images/wl/star.svg" />
                  <img src="/images/wl/star.svg" />
                </div>
                <p className="mt-4 testimonial-description">
                  "{testimonial.description}"
                </p>

                {/* <Link
                  to="/weight-loss/free-consultation"
                  className="bk-consultation orange-background"
                >
                  Book Your Consultation Now
                  <img src="/images/wl/arrow_forward.svg" />
                </Link> */}
                <div className="d-flex justify-content-end">
                  <img
                    src="/images/wl/quote-bottom.svg"
                    className=""
                    width={48}
                    height={48}
                  />
                </div>
              </div>
              <img src={testimonial.image} className="testimonial-images" />
            </div>
          ))}
        </div>
      </div>
      <AllPrograms showPcos={true} showDiabetics={true} className="pb-4" />
      <TalkToExperts selectedProgram="Weight Loss" />
      <div className="footer-wrapper--header d-flex align-items-center justify-content-between flex-direction-column-mobile mt-4 custom-container footer-border pb-4 pt-2">
        <div className="icon-logo d-flex  align-items-center gap-md-5 flex-column flex-md-row gap-2">
          <p className="mb-3">Follow us</p>
          <ul>
            <li>
              <a href="https://twitter.com/HealthTunnel" target="_blank">
                <img src={Twittersvg} />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/Healthtunnel/" target="_blank">
                <img src={Facebooksvg} />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/healthtunnel_?igsh=YzljYTk1ODg3Zg=="
                target="_blank"
              >
                <img src={Instagramsvg} />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCIrAknx2mNOIohWD23cpE4w"
                target="_blank"
              >
                <img src={Youtubesvg} />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://in.pinterest.com/HealthTunnel/_created"
              >
                <img src={Pinterest} />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/health-tunnel"
              >
                <img src={LinkedinSVG} />
              </a>
            </li>
            <li>
              <a target="_blank" href="http://healthtunnel.tumblr.com/">
                <img src={Ticon} />
              </a>
            </li>
            <li>
              <a target="_blank" href="http://healthtunnel.tumblr.com/">
                <img src={Thread} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default WeightLossPage;
