import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";
import { getValue } from "../../utils/lodash";
import { createConsulationForm } from "@services/APIs/common.service";

const TalkToExperts = ({ selectedProgram }) => {
  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);
  const navigate = useNavigate();
  const params = useParams();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  const lifestyleEnum = ["Active", "Sedentary", "Moderately active"];

  const [request, setRequest] = useState({
    name: "",
    phone: "",
    email: "",
    gender: "Male",
    lifestyle: lifestyleEnum[0],
    program: selectedProgram,
    is_paid: false,
  });

  const [activeTab, setActiveTab] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState({});
  const [checkAgreed, setCheckAgreed] = useState(true);
  const toggleAgreed = () => {
    setCheckAgreed(!checkAgreed);
  };
  const handleSubmitForm = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      // window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      try {
        setIsLoading(true);
        try {
          if (!getValue(request, `is_paid`, false)) {
            let payload = {
              data: {
                name: getValue(request, `name`, ""),
                phone: getValue(request, `phone`, ""),
                email: getValue(request, `email`, ""),
                gender: getValue(request, `gender`, ""),
                lifestyle: getValue(request, `lifestyle`, ""),
                program: getValue(request, `program`, ""),
                is_paid: false,
              },
            };
            let resp = await createConsulationForm(payload);
            console.log("resp:", resp);
            if (resp) {
              toast.success("Submitted successfully");
              navigate(`/weight-loss/free-consultation/thank`);
              setIsLoading(false);
            } else {
              toast.error(resp.error.message);
              setIsLoading(false);
            }
          } else {
            let payload = {
              data: {
                name: getValue(request, `name`, ""),
                phone: getValue(request, `phone`, ""),
                email: getValue(request, `email`, ""),
                gender: getValue(request, `gender`, ""),
                lifestyle: getValue(request, `lifestyle`, ""),
                is_paid: true,
              },
            };
            let resp = await createConsulationForm(payload);
            if (resp) {
              let options = {
                name: getValue(resp, `name`, ""),
                phone: getValue(resp, `phone`, ""),
                email: getValue(resp, `email`, ""),
                gender: getValue(resp, `gender`, ""),
                lifestyle: getValue(resp, `lifestyle`, ""),
                createdAt: moment(getValue(resp, `createdAt`, "")).format(
                  "YYYY-MM-DD"
                ),
                updatedAt: moment(getValue(resp, `updatedAt`, "")).format(
                  "YYYY-MM-DD"
                ),
                publishedAt: moment(getValue(resp, `publishedAt`, "")).format(
                  "YYYY-MM-DD"
                ),
                is_paid: getValue(resp, `is_paid`, ""),
                key: getValue(resp, `key`, ""),
                description: getValue(resp, `description`, ""),
                order_id: getValue(resp, `id`, ""),
                currency: "INR",
                amount: getValue(resp, `amount`, ""),
                displayAmount: getValue(resp, `displayAmount`, ""),
                prefill: {
                  name: getValue(resp, `prefill.name`, ""),
                  email: getValue(resp, `prefill.email`, ""),
                  contact: getValue(resp, `prefill.phone`, ""),
                },
                receipt: getValue(resp, `receipt`, ""),
                handler: async (response) => {
                  setPaymentInfo(response);
                  let input = {
                    input: {
                      data: {
                        signature: getValue(response, "razorpay_signature", ""),
                        payment_id: getValue(
                          response,
                          "razorpay_payment_id",
                          ""
                        ),
                        order_id: getValue(response, "id", ""),
                      },
                    },
                  };
                  try {
                    const razorpay_order_id = getValue(
                      response,
                      `razorpay_order_id`,
                      ""
                    );
                    const url = `https://admin.healthtunnel.com/api/consultation-form/order_status/${razorpay_order_id}`;
                    const captureResponse = await axios.get(url, {});
                    if (getValue(captureResponse, `data.is_completed`, false)) {
                      navigate(`/weight-loss/free-consultation/thank-you`);
                      setIsLoading(false);
                    } else {
                      toast.error(
                        "Something went wrong, Please try after sometime"
                      );
                    }
                    setIsLoading(false);
                  } catch (err) {
                    setIsLoading(false);
                  }
                },
                theme: {
                  color: "#686CFD",
                },
              };
              const rzp1 = new window.Razorpay(options);
              rzp1.open();
            }
          }
        } catch (error) {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRequest({
      ...request,
      [name]: value,
    });
  };
  return (
    <div className="talk-expert-container">
      <div className="custom-container py-5 ">
        <h6 className="programs-heading text-center">Talk To Our Experts</h6>
        <div className="d-flex column-gap-3 mt-5 flex-wrap flex-lg-nowrap">
          <div className="form-group rebeyu-font mb-3 w-100">
            <div>
              <label className="mb-1" htmlFor="full-name">
                Full Name *
              </label>
              <input
                type="text"
                value={getValue(request, "name", "")}
                name="name"
                onChange={handleChange}
                className="form-control lab-text-contact-form-wrapper__input lab-text-contact-form-wrapper__input--spacing input-border"
                placeholder="Enter Full Name"
                id="name"
              />
            </div>
            {
              <span className="form-error">
                {simpleValidator.current.message(
                  "Full Name",
                  getValue(request, `name`, ""),
                  "required"
                )}
              </span>
            }
          </div>
          <div className="form-group rebeyu-font mb-3 w-100">
            <label className="mb-1" htmlFor="mob-no">
              Mobile Number *
            </label>
            <div className="lab-text-contact-form-wrapper__contact-form-position">
              <input
                value={getValue(request, "phone", "")}
                name="phone"
                onChange={handleChange}
                type="number"
                className="form-control lab-text-contact-form-wrapper__input input-border"
                placeholder="Enter Mobile Number"
                id="mob-no"
              />
              <p className="lab-text-contact-form-wrapper__contact-form-position lab-text-contact-form-wrapper__contact-form-position--absolute">
                +91
              </p>
            </div>
            {
              <span className="form-error">
                {simpleValidator.current.message(
                  "Mobile Number",
                  getValue(request, `phone`, ""),
                  "required|integer|size:10"
                )}
              </span>
            }
          </div>
          <div className="form-group rebeyu-font mb-3 w-100">
            <label className="mb-1" htmlFor="full-name">
              Email *
            </label>
            <input
              type="text"
              value={getValue(request, "email", "")}
              name="email"
              onChange={handleChange}
              className="form-control lab-text-contact-form-wrapper__input lab-text-contact-form-wrapper__input--spacing input-border"
              placeholder="Enter Email Address"
              id="email"
              required
            />
            {
              <span className="form-error">
                {simpleValidator.current.message(
                  "Email",
                  getValue(request, `email`, ""),
                  "required"
                )}
              </span>
            }
          </div>
        </div>
        <div className="d-flex align-items-center gap-2 custom-checkbox">
          <input
            type="checkbox"
            id="agree"
            name="agree"
            value={checkAgreed}
            checked={checkAgreed}
            onChange={toggleAgreed}
          />
          <label for="agree">
            I agree to the{" "}
            <Link
              to="/terms-of-service"
              target="__blank"
              className="blue_bold_text"
            >
              T&C
            </Link>
          </label>
        </div>
        <div className="d-flex justify-content-center my-3">
          <button
            className="bk-consultation orange-background input-border mt-2"
            onClick={handleSubmitForm}
            disabled={isLoading || !checkAgreed}
          >
            {isLoading ? "Submitting" : "Get a call back"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TalkToExperts;
