import React from "react";
import "../../assets/scss/footer/site-footer.scss";
//import Sitelogo from '../..//assets/svg/logo/logo.svg';
import Twittersvg from "../..//assets/images/footerIcons/twitter.svg";
import Ticon from "../..//assets/images/footerIcons/t-icon.png";
// import Phonesvg from '../..//assets/images/footerIcons/phone.svg';
import Mailsvg from "../..//assets/images/footerIcons/mail.svg";
import Phonesvg from "../..//assets/images/footerIcons/phone.svg";
import Addresssvg from "../..//assets/images/footerIcons/address.svg";
import Facebooksvg from "../..//assets/images/footerIcons/facebook.svg";
import Instagramsvg from "../..//assets/images/footerIcons/instagram.svg";
import Youtubesvg from "../..//assets/images/footerIcons/youtube.svg";
import Pinterest from "../..//assets/images/footerIcons/pinterest.png";
import LinkedinSVG from "../..//assets/images/footerIcons/linkedin.svg";
import Thread from "../..//assets/images/footerIcons/thread.svg";
import { NavLink } from "react-router-dom";
import Sitelogo from "../../assets/svg/logo/logo.svg";

export default class Footer extends React.Component {
  render() {
    const { weightlossFooter } = this.props;

    return (
      <footer className="footer-wrapper">
        <div className="custom-container">
          <div className="footer-wrapper--body">
            <div className="company-details d-flex justify-content-between flex-direction-column-mobile">
              <div className="w-100">
                <div className="footer-detail__left-wrapper d-flex justify-content-between flex-direction-column-mobile w-100">
                  <div className="logo">
                    <div className="icon-logo d-flex align-items-center">
                      <a
                        aria-current="page"
                        className="logo-wrapper d-flex align-items-center active"
                        href="/"
                      >
                        <img
                          src={Sitelogo}
                          className="img-fluid"
                          width="38"
                          height="38"
                          alt="HEALTH TUNNEL"
                        />
                        <div className="logo-wrapper--meta ml-2 text-left">
                          <h6 className="bold-font">
                            HEALTH TUNNEL <sup>®</sup>
                          </h6>
                          <p className="small-font footer-logo-text p-0">
                            Well Beyond Healthcare
                          </p>
                        </div>
                      </a>
                    </div>
                    <p>
                      {weightlossFooter
                        ? "Healthtunnel with three years of industry expertise has built a robust user base through our Android, iOS app, and website. Specializing in weight loss, smart clinics, and the aggregation of pharmacy and medical services. Healthtunnel aggressively expanding into the weight loss with a team of qualified Nutritionists and dieticians and standalone tracking app to position ourselves as a leader in comprehensive healthcare solutions."
                        : "Health Tunnel is India’s Largest Healthcare Community with All-in-one Health App that helps you and provides Special Deals and Discounts For You"}
                    </p>
                  </div>
                  <div className="sales common">
                    <h5 className="bold-font">SALES & SUPPORT</h5>
                    <ul className="mt-2">
                      <li className="pb-2">
                        <div className="d-flex align-items-center">
                          <img src={Phonesvg} className="mr-3" />
                          <p className="ms-2">+91 7314599740</p>
                        </div>
                      </li>
                      <li className="pb-2">
                        <div className="d-flex align-items-center">
                          <img src={Mailsvg} className="mr-3" />
                          <p className="ms-2">care@healthtunnel.in</p>
                        </div>
                      </li>
                      <li className="pb-2">
                        <div className="d-flex align-items-center">
                          <img src={Addresssvg} className="mr-3" />
                          <p className="ms-2">
                            AABS HEALTHCARE PVT LTD, <br />
                            Bangalore & Hyderabad
                          </p>
                        </div>
                      </li>
                    </ul>
                    <h5 className="bold-font mt-4">WORK WITH US</h5>
                    <ul className="mt-2">
                      <li className="pb-2">
                        <div className="d-flex align-items-center">
                          <img src={Mailsvg} className="mr-3" />
                          <p className="ms-2">joinus@healthtunnel.in</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="others common">
                    <h5 className="bold-font">LINKS</h5>
                    <ul className="mt-4">
                      <li>
                        <NavLink to="/">Home</NavLink>
                      </li>
                      {/* <li>
                        <NavLink to="/categroies">Categories</NavLink>
                      </li> */}
                      {/* <li><a href="#0">FAQ'S</a></li> */}
                      {/* <li><a href="#0">Blogs</a></li> */}
                      {/* <li><a href="#0">Testimonials</a></li> */}
                      <li>
                        <NavLink to="/become-partner-with-us">
                          Partner with us
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/category-detail/2">
                          Health Checks / Lab Tests
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/become-partner-with-us">
                          X-Ray and Scans
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/videos-page">Videos</NavLink>
                      </li>
                      <li>
                        <NavLink to="https://apps.apple.com/in/app/health-tunnel/id1572626993">
                          App Store
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="https://play.google.com/store/apps/details?id=com.healthtunnel">
                          Google Play
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/become-partner-with-us">
                          Contact us
                        </NavLink>
                      </li>
                      {/* <li>
                        <NavLink to="/instant-personal-loans-online">Personal Loan</NavLink>
                      </li> */}
                      {/* <li>
                        <NavLink to="/home-loan">Home Loan</NavLink>
                      </li> */}
                      {/* <li>
                        <NavLink to="/business-loans-online">Business Loan</NavLink>
                      </li> */}
                    </ul>
                  </div>
                  {/*  <div className="others common">
                    <h5 className="bold-font d-none-mobile">&nbsp;</h5>
                    <ul className="mt-sm-4">
                     <li>
                        <a
                          href={`https://healthtunnelblog.blogspot.com/`}
                          target="_blank"
                        >
                          Blog
                        </a>
                      </li> 
                      <li>
                        <NavLink to="/blog">Blog</NavLink>
                      </li>
                      <li>
                        <NavLink to="/about-us-page">About us</NavLink>
                      </li>
                     <li>
                        <NavLink to="#0">Pay Bills</NavLink>
                      </li> 
                      <li>
                        <NavLink to="/videos-page">Videos</NavLink>
                      </li>
                      <li>
                        <NavLink to="/advertise-with-us-page">
                          Advertise with us
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/contact-us-page">Contact us</NavLink>
                      </li>
                   <li>
                    <NavLink to="/health-insurance">Health Insurance</NavLink>
                  </li> 
                    </ul>
                  </div>*/}
                </div>
                {!weightlossFooter && (
                  <div className="footer-wrapper--header d-flex align-items-center justify-content-between flex-direction-column-mobile mt-4">
                    <div className="icon-logo">
                      <p className="mb-3">Follow us</p>
                      <ul>
                        <li>
                          <a
                            href="https://twitter.com/HealthTunnel"
                            target="_blank"
                          >
                            <img src={Twittersvg} />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.facebook.com/Healthtunnel/"
                            target="_blank"
                          >
                            <img src={Facebooksvg} />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/healthtunnel_?igsh=YzljYTk1ODg3Zg=="
                            target="_blank"
                          >
                            <img src={Instagramsvg} />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.youtube.com/channel/UCIrAknx2mNOIohWD23cpE4w"
                            target="_blank"
                          >
                            <img src={Youtubesvg} />
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://in.pinterest.com/HealthTunnel/_created"
                          >
                            <img src={Pinterest} />
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.linkedin.com/company/health-tunnel"
                          >
                            <img src={LinkedinSVG} />
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="http://healthtunnel.tumblr.com/"
                          >
                            <img src={Ticon} />
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="http://healthtunnel.tumblr.com/"
                          >
                            <img src={Thread} />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
              <div className="company common qr-code-wrapper d-flex gap-3 ms-4">
                <a
                  href="https://apps.apple.com/in/app/health-tunnel/id1572626993"
                  target="_blank"
                  className="d-inline-block mb-sm-4"
                >
                  <img
                    src="/images/footer/applestore.png"
                    className="img-fluid"
                    width="114"
                    alt="Health Tunnel"
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.healthtunnel"
                  target="_blank"
                  className="d-inline-block"
                >
                  <img
                    src="/images/footer/googleplay.png"
                    className="img-fluid"
                    width="114"
                    alt="Health Tunnel"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="footer-wrapper--footer d-flex align-items-center justify-content-between mt-3 flex-direction-column-mobile">
            <div className="footer-links">
              <ul>
                <li>
                  <a href="/privacy-policy-page">Privacy</a>
                </li>
                <li>
                  <a href="/terms-of-service">Terms of Service</a>
                </li>
                <li>
                  <a href="/">Refund, Cancellation & Transfer Policy</a>
                </li>
              </ul>
            </div>
            <p>Copyright © Healthtunnel. All Rights Reserved</p>
          </div>
        </div>
      </footer>
    );
  }
}
