import HomePage from "../pages/Home/index.jsx";
import CategoryDetailPage from "../pages/Categories/index.jsx";
import VideosPage from "../pages/Videos/index.jsx";
import SubCategoryDetailPage from "../pages/SubCategories/index.jsx";
import BloodDonationPage from "../pages/BloodDonation/index.jsx";
import WeightLossPage from "../pages/WeightLoss/index.jsx";
import FreeConsultationForm from "../pages/WeightLoss/components/free-consultation-form.js";
import AboutUs from "../pages/about-us.js";
import PartnerWithUs from "@pages/partner-with-us.js";
import AdvertiseWithUs from "@pages/advertise-with-us.js";
import Contactus from "@pages/contact-us.js";
import Success from "@pages/success.js";
import Privacypolicy from "@pages/privacy-policy.js";
import TermsService from "@pages/terms-of-service.js";
import Redcliff from "@pages/redcliff/index.js";
import Healthians from "@pages/Healthians/index.jsx";
import Apollo from "@pages/Apollo/index.jsx";
import Thyrocare from "@pages/Thyrocare/index.jsx";
import Blog from "@pages/blog.js";
import BlogPost from "@pages/BlogPost/index.jsx";
import Pagenotfound from "@pages/page-not-found.js";
import ThankYou from "@pages/thank-you.js";
import Thanks from "@pages/thanks.js";
import PCOSReversal from "@pages/Pcos/index.jsx";
import DiabetesReversal from "@pages/DiebetesReversal/index.jsx";

const routes = [
  { path: "/", name: "labtest", component: HomePage },
  { path: "/*", name: "PageNotFound", component: Pagenotfound },
  {
    path: "/category-detail/:slug",
    name: "labtest",
    component: CategoryDetailPage,
  },
  {
    path: "/sub-category-detail/:main_slug/:slug",
    name: "labtest",
    component: SubCategoryDetailPage,
  },
  {
    path: "/blood-donation",
    name: "Videos",
    component: BloodDonationPage,
  },
  {
    path: "/weight-loss",
    name: "Videos",
    component: WeightLossPage,
  },
  {
    path: "/pcos-reversal",
    name: "Videos",
    component: PCOSReversal,
  },
  {
    path: "/diabetes-reversal",
    name: "Videos",
    component: DiabetesReversal,
  },
  {
    path: "/weight-loss/free-consultation",
    name: "Videos",
    component: FreeConsultationForm,
  },
  {
    path: "/weight-loss/free-consultation/thank",
    name: "Videos",
    component: Thanks,
  },
  {
    path: "/weight-loss/free-consultation/thank-you",
    name: "Videos",
    component: ThankYou,
  },
  {
    path: "/redcliff",
    name: "redcliff",
    component: Redcliff,
  },
  {
    path: "/healthians",
    name: "healthians",
    component: Healthians,
  },
  {
    path: "/Apollo",
    name: "Apollo",
    component: Apollo,
  },
  {
    path: "/Thyrocare",
    name: "Thyrocare",
    component: Thyrocare,
  },

  {
    path: "/blog",
    name: "Blog",
    component: Blog,
  },
  {
    path: "/blog/:id/:slug",
    name: "BlogPost",
    component: BlogPost,
  },
  {
    path: "/about-us-page",
    name: "Videos",
    component: AboutUs,
  },
  {
    path: "/become-partner-with-us",
    name: "PartnerWithUs",
    component: PartnerWithUs,
  },
  { path: "/contact-us-page", name: "Contactus", component: Contactus },

  {
    path: "/advertise-with-us-page",
    name: "AdvertiseWithUs",
    component: AdvertiseWithUs,
  },
  { path: "/success", name: "Success", component: Success },
  {
    path: "/privacy-policy-page",
    name: "Privacypolicy",
    component: Privacypolicy,
  },
  { path: "/terms-of-service", name: "TermsService", component: TermsService },

  {
    path: "/videos-page",
    name: "Videos",
    component: VideosPage,
  },
];

export default routes;
